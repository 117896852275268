import React from "react"
import Layout from "../components/layout"
import {
  FaRegEnvelope,
  FaLinkedinIn,
  FaTwitter,
  FaGithub,
  FaStackOverflow,
} from "react-icons/fa"

const mailToUrl = `mailto:eric.h.francis1+blog@gmail.com?Subject=Hello`
const linkedinUrl = `https://www.linkedin.com/in/erichfrancis/`
const twitterUrl = `https://twitter.com/EricFrancisImpl`
const githubUrl = `https://github.com/ericfrancis`
const stackOverFlowUrl = `https://stackoverflow.com/users/843542/eric-francis`

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `3rem` }}>
    <a
      href={props.to}
      target="_blank"
      title={`Contact Eric Francis at ${props.title}`}
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  </li>
)

const Contact = () => (
  <Layout>
    <h1>Contact</h1>
    <p>You can reach me here:</p>
    <ul style={{ margin: `0`, listStyle: `none`, float: `left` }}>
      <ListLink to={twitterUrl} title="Twitter">
        <FaTwitter />
      </ListLink>
      <ListLink to={linkedinUrl} title="LinkedIn">
        <FaLinkedinIn />
      </ListLink>
      <ListLink to={githubUrl} title="GitHub">
        <FaGithub />
      </ListLink>
      <ListLink to={stackOverFlowUrl} title="Stack Overflow">
        <FaStackOverflow />
      </ListLink>
      <li style={{ display: `inline-block` }}>
        <a href={mailToUrl} target="_top" title="Email Eric Francis">
          <FaRegEnvelope />
        </a>
      </li>
    </ul>
  </Layout>
)

export default Contact
